<template>
  <div class="page_box">
    <div class="box_top">
      <Header class="page_hea" />
    </div>
    <div class="box_slot">
      <div class="slot_left">
        <div class="title_o" style="padding-top: 10px">
          企业简介
        </div>
        <div class="con_data">
          <p>
            北京凯特伟业科技有限公司，是一家专门从事企业管理咨询和企业信息化落地服务的信息技术企业。多年来，凯特一直为中石油、中石化在内的央企、国企等大型集团性企业提供企业管理咨询和企业信息化落地服务，凭借专业的技术能力和优质完善的服务，获得了客户的一致好评与行业的高度认可。
          </p>
          <p>
            目前，公司主要产品包括JEPaaS开发平台、蒜瓣
            SCRM等，并提供定制开发和SaaS服务。
          </p>
        </div>
        <btn class="hzgy" :content="'合作共赢'" @click="handleBtn" />
      </div>
      <div class="slot_right">
        <!-- <baidu-map
          class="map bm-view"
          :center="{lng: 116.3849897961, lat: 39.9773611119}"
          :scroll-wheel-zoom="true"
          :zoom="13"
        >
          <bm-marker
            :position="{lng: 116.3849897961, lat: 39.9775}"
            :dragging="true"
            animation="BMAP_ANIMATION_BOUNCE"
          />
        </baidu-map> -->
        <div class="map-view">
          <img
            :src="gsMap"
            alt=""
            @click="
              openUrl(
                'https://map.baidu.com/search/%E9%87%91%E6%BE%B3%E5%9B%BD%E9%99%85/@12956001.034221955,4834880.465,19z?querytype=s&da_src=shareurl&wd=%E9%87%91%E6%BE%B3%E5%9B%BD%E9%99%85&c=131&src=0&wd2=%E5%8C%97%E4%BA%AC%E5%B8%82%E6%B5%B7%E6%B7%80%E5%8C%BA&pn=0&sug=1&l=13&b=(12928334,4823103;12989774,4852831)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=9fbfeef2ed509a44ede4a568&device_ratio=1'
              )
            "
          />
        </div>
      </div>
    </div>

    <div class="box_media">
      <div class="title_media">
        媒体报道
      </div>
      <div class="media_content">
        <div
          v-for="(item, index) in mediaData"
          :key="index"
          class="media_items"
        >
          <img :src="item.img" alt="" />
          <div class="text_wrp">
            <p class="title">
              {{ item.title }}
            </p>
            <p class="text">
              {{ item.text }}
            </p>
            <span class="btn" @click="openUrl(item.url)">
              <!-- <img :src="arrow_gray" alt=""> -->
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="box_o">
      <div class="title_o">
        团队简介
      </div>
      <div class="con_o">
        <div class="img_o">
          <img v-lazy="img_o" alt="" />
        </div>
        <div class="data_o">
          JEPaaS隶属北京凯特伟业科技有限公司。作为凯特科技推出的核心产品，JEPaaS的诞生不是偶然，
          而是经过10年呕心沥血的努力研发而成。10年来，JEPaaS一直专注于为各行业、各类型企业提供信息化落地与数字化升级服务。
          经过十多年的发展，JEPaaS商用化程度及性能水平与国际品牌同类型产品拥有同等竞争力，具备了持续创新能力与市场竞争力。
          <br /><br />在信息化管理成为主流，简约而轻便的SaaS云服务备受青睐的当下。JEPaaS团队厚积薄发，向SaaS市场延伸。
          凭借十多年的技术积累和经验沉淀，JEPaaS在SaaS领域可谓是如鱼得水，游刃有余。研发的“轻云蒜瓣”系列企业管理应用系统，
          以蒜瓣SCRM为代表，率先进军SaaS 市场，获得了十分热烈的市场反应。<br /><br />目前，JEPaaS研发团队达百余人。无论从平台支持，
          还是到升级维护，以及进军SaaS市场，都有着强大而深厚的基础。让SaaS开发变得更快捷、更容易，是JEPaaS始终坚持的信念。
        </div>
      </div>
    </div>
    <div class="box_s">
      <div class="title_s">
        发展历程
      </div>
      <div class="box_lin">
        <div class="list_lin">
          <div class="lin_con">
            <span />
          </div>
          <div class="lin_con">
            <span />
          </div>
          <div class="lin_con">
            <span />
          </div>
          <div class="lin_con">
            <span />
          </div>
          <div class="lin_con">
            <span />
          </div>
          <div class="lin_con">
            <span />
          </div>
        </div>
      </div>
      <div class="box_listl" style="top:30%;">
        <div class="list_title">
          2018年
        </div>
        <div class="list_data">
          发布7.0产品，命名为JEPaaS（大象）<br />
          追求帮助传统ISV公司完成SaaS产品落地与公司转型。<br />
          定位:专为SaaS而生的PaaS平台<br />
        </div>
      </div>
      <div class="box_listl" style="top:53.3%;">
        <div class="list_title">
          2014年
        </div>
        <div class="list_data">
          发布3.0产品命名为JEPF（大象）<br />
          追求移动端应用的可配性。<br />
          定位：快速开发平台<br />
        </div>
      </div>
      <div class="box_listl" style="top:76.5%;">
        <div class="list_title">
          2008年
        </div>
        <div class="list_data">
          发布1.0产品，命名为Ant（蚂蚁）<br />
          追求团队协同快速开发，敏捷交付。<br />
          定位：快速开发平台<br />
        </div>
      </div>
      <div class="box_listr" style="top:18%;">
        <div class="list_title">
          2019年
        </div>
        <div class="list_data">
          轻云蒜瓣CRM、OA、MES、EAM、进销存、物业云、<br />
          工业云等基于JEPaaS而孵化的SaaS产品逐一推向市场，<br />
          为企业带来巨大的商业前景。<br />
        </div>
      </div>
      <div class="box_listr" style="top:41.5%;">
        <div class="list_title">
          2017年
        </div>
        <div class="list_data">
          发布5.0产品命名为JEPLUS（大象）<br />
          追求支持大集团高并发场景的应用<br />
          定位：大集群与服务华场景下的快速开发平台<br />
        </div>
      </div>
      <div class="box_listr" style="top:65%;">
        <div class="list_title">
          2011年
        </div>
        <div class="list_data">
          发布2.0产品命名为Pcat（波斯猫）<br />
          追求敏捷、高效、优雅的用户体验。<br />
          定位：快速开发平台<br />
        </div>
      </div>
    </div>
    <div class="box_th">
      <div class="title_th">
        行业肯定
      </div>
      <div class="img_box">
        <div class="img_bor">
          <img v-lazy="imgth_1" class="img_width1" alt="" />
        </div>
        <div class="img_bor">
          <img v-lazy="imgth_2" class="img_width2" alt="" />
        </div>
        <div class="img_bor">
          <img v-lazy="imgth_3" class="img_width3" alt="" />
        </div>
        <div class="img_bor">
          <img v-lazy="imgth_4" class="img_width4" alt="" />
        </div>
      </div>
      <div class="img_box">
        <div class="img_bors">
          <img v-lazy="imgth_5" class="img_width5" alt="" />
        </div>
        <div class="img_bors">
          <img v-lazy="imgth_6" class="img_width6" alt="" />
        </div>
        <div class="img_bors">
          <img v-lazy="imgth_7" class="img_width7" alt="" />
        </div>
      </div>
      <div class="img_box">
        <div class="img_bors">
          <img v-lazy="imgth_8" class="img_width5" alt="" />
        </div>
        <div class="img_bors">
          <img v-lazy="imgth_9" class="img_width6" alt="" />
        </div>
        <div class="img_bors">
          <img v-lazy="imgth_10" class="img_width7" alt="" />
        </div>
      </div>
      <div class="img_box">
        <div class="img_bors img_height_auto">
          <img v-lazy="imgth_11" class="img_width11" />
        </div>
        <div class="img_bors img_height_auto">
          <img v-lazy="imgth_12" class="img_width12" />
        </div>
      </div>
      <div class="data_box">
        <p>JEPaaS平台获评“2016年度能源行业优秀软件产品”</p>
        <p>JEPaaS平台获评“2016年度电力行业优秀软件产品”</p>
        <p>2017年，JEPaaS CEO云凤程被评为“信息产业年度创新人物”</p>
        <p>2017年，凯特公司荣获中国产业与创新互联网大会“最具推动力企业奖”</p>
        <p>JEPaaS获评“2017年度北京市优秀软件产品”</p>
        <p>JEPaaS荣获“2018年度企业数字化转型最佳解决方案奖”</p>
        <p>JEPaaS荣获“2018年度金融IT服务商优秀解决方案奖”</p>
        <p>JEPaaS获评“年度最具投资价值PaaS平台”</p>
        <p>JEPaaS获评“年度最具竞争力SaaS提供商”</p>
      </div>

      <!-- <Footer></Footer> -->
    </div>
    <div class="box_fi">
      <div class="title_fi">
        加入我们
      </div>
      <div v-for="item in addData" :key="item.id" class="box_list">
        <div
          class="list_title"
          :style="{
            color: item.active ? '#FFFFFF' : '#666666',
            background: item.active ? '#FF3041' : '#F8F8F8',
            boxShadow: item.active
              ? '0px 10px 20px 0px rgba(255,48,65,0.3)'
              : ''
          }"
          @click="listShow(item)"
        >
          <div
            class="list_name"
            :style="{ color: item.active ? '#FFFFFF' : '#333333' }"
          >
            {{ item.name }}
          </div>
          <div class="list_right">
            <div>薪资：{{ item.salary }}</div>
            <div>经验：{{ item.empiric }}</div>
            <div>工作地点：{{ item.location }}</div>
            <div
              :style="{ background: item.active ? '#FFFFFF' : '#FF3041' }"
              class="list_but"
            >
              <span v-if="item.active" class="span_act" />
              <span v-else class="span_no" />
            </div>
          </div>
        </div>
        <transition name="adc">
          <div v-show="item.active" class="list_box">
            <div v-for="(itemm, index) in item.messgData" :key="index + 'a'">
              <div class="box_title">
                {{ itemm.name }}
              </div>
              <p v-for="(itemc, ind) in itemm.arr" :key="ind + 'a'">
                {{ itemc }}
              </p>
            </div>
            <div class="contact_box">
              <img src="@/assets/imgs/about/elme.png" alt="" />
              简历投递：shim@ketr.com.cn
              <img
                class="iphone_m"
                src="@/assets/imgs/about/iphone.png"
                alt=""
              />
              联系方式：石女士 15010612217
            </div>
          </div>
        </transition>
      </div>
      <Footer class="oot" />
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import img_o from '@/assets/imgs/about/img_o.png'
import imgth_1 from '@/assets/imgs/about/imgth_1.png'
import imgth_2 from '@/assets/imgs/about/imgth_2.png'
import imgth_3 from '@/assets/imgs/about/imgth_3.png'
import imgth_4 from '@/assets/imgs/about/imgth_4.png'
import imgth_5 from '@/assets/imgs/about/imgth_5.png'
import imgth_6 from '@/assets/imgs/about/imgth_6.png'
import imgth_7 from '@/assets/imgs/about/imgth_7.png'
import imgth_8 from '@/assets/imgs/about/imgth_8.png'
import imgth_9 from '@/assets/imgs/about/imgth_9.png'
import imgth_10 from '@/assets/imgs/about/imgth_10.png'
import imgth_11 from '@/assets/imgs/about/imgth_11.png'
import imgth_12 from '@/assets/imgs/about/imgth_12.png'
import img_top from '@/assets/imgs/about/img_top.png'
import media_1 from '@/assets/imgs/about/media_1.png'
import media_2 from '@/assets/imgs/about/media_2.png'
// import media_3 from '@/assets/imgs/about/media_3.png';
import media_4 from '@/assets/imgs/about/media_4.png'
import arrow_white from '@/assets/imgs/about/arrow_white.png'
import arrow_gray from '@/assets/imgs/about/arrow_gray.png'
import mapImg from '@/assets/imgs/about/map.png'
import btn from '@/components/btn.vue'

export default {
  name: 'AboutPage',
  components: {
    Header,
    Footer,
    btn
  },
  data() {
    return {
      arrow_gray,
      arrow_white,
      activeBut: 0,
      img_top,
      img_o,
      imgth_1,
      imgth_2,
      imgth_3,
      imgth_4,
      imgth_5,
      imgth_6,
      imgth_7,
      imgth_8,
      imgth_9,
      imgth_10,
      imgth_11,
      imgth_12,
      addData: [
        {
          id: 1,
          name: 'java高级工程师',
          salary: '面议',
          empiric: '5-10年',
          location: '北京',
          active: false,
          messgData: [
            {
              name: '任职要求',
              arr: [
                '1、计算机或相关专业本科以上学历，5年及以上相关工作经验；',
                '2、JAVA基础扎实，理解io、多线程、集合等基础框架，对JVM原理有一定的了解；对于你用过的开源框架，能了解到它的原理和制；',
                '3、至少熟悉一种大型数据库（mysql、oracle、postgrelSQL），有sql优化的经验；',
                '4、熟悉分布式系统的设计和应用，熟悉分布式、缓存、消息等机制，有高并发应用开发经验，熟悉常用的分布式解决方案，能基于问题给出解决方案；',
                '5、有较强的团队沟通协作能力和项目风险管理能力，可以push多团队协作按时交付项目；',
                '6、学习能力强，具备耐心/细心的品质，喜欢去看及尝试最新的技术，从技术趋势和思路上能影响技术团队。'
              ]
            },
            {
              name: '岗位职责',
              arr: [
                '1、负责JEPaaS系统相关的需求开发，保证需求按时、高质量交付；',
                '2、深入理解和分析产品需求，能不断拓展业务场景边界，撰写设计文档和系统开发；',
                '3、愿意接受复杂业务逻辑、海量数据、服务高可用的挑战，推动系统可用性和可扩展性的提升；',
                '4、具有良好的系统抽象能力，在系统中台能力建设方面能提出创新的解决思路和方案，为团队引入创新技术。'
              ]
            },
            {
              name: '满足以下条件我们会优先考虑',
              arr: [
                '1.精通移动端HTML5开发技术者优先',
                '2.口才优秀，有过项目经理工作经验者优先'
              ]
            },
            {
              name: '薪资福利',
              arr: [
                '上市公司+30k~50k+饭补话补+带薪培训+五险一金+生日关怀+团建活动+带薪休假+周末双休+年终奖金+老板Nice'
              ]
            }
          ]
        },
        {
          id: 2,
          name: '前端高级工程师',
          salary: '面议',
          empiric: '5-10年',
          location: '北京',
          active: false,
          messgData: [
            {
              name: '任职要求',
              arr: [
                '1、本科以上学历，5年以上工作经验，计算机相关专业；',
                '2、熟悉掌握W3C标准、Div/Css/Html，对多浏览器的兼容性问题有处理经验；',
                '3、精通JavaScript语言，了解ES5，ES6，对H5开发丰富有实际经验；',
                '4、对前后端分离的架构模式有丰富的实战经验；',
                '5、有webpack/Gulp/Grunt等前端工具使用经验；',
                '6、熟练掌握前端自动化和工程化相关流程和工具；',
                '7、熟练两种第三方前端框架和UI组件，如：Vue、React、Node.js、Ant Design和Element；'
              ]
            },
            {
              name: '岗位职责',
              arr: [
                '1. 根据需求规格说明书，负责JEPaaS PC端和APP端页面设计与代码的编写；',
                '2. 前端技术架构持续优化；',
                '3. 与后端工程师、产品、测试等保持良好的沟通和协作 ；',
                '4. 其他公司交办的工作。'
              ]
            },
            {
              name: '其他要求',
              arr: [
                '1.善于团队合作，能与产品/设计部门交流，沟通能力强；',
                '2.工作主动性强，具有积极的自我驱动能力；',
                '3.分析和解决问题能力强；',
                '4.很强的学习能力，能够熟练阅读英文资料并善用Google/StackOverflow/Quora/GitHub等网站；',
                '5.能够适应多种工作角色的迅速转换，有创业热情，抗压能力强；',
                '6.面试请带上简历和作品展示，谢谢~'
              ]
            },
            {
              name: '薪资福利',
              arr: [
                '上市公司+30k~50k+饭补话补+带薪培训+五险一金+生日关怀+团建活动+带薪休假+周末双休+年终奖金+老板Nice'
              ]
            }
          ]
        },
        {
          id: 3,
          name: 'DBA',
          salary: '面议',
          empiric: '5-10年',
          location: '北京',
          active: false,
          messgData: [
            {
              name: '任职要求',
              arr: [
                '1. 计算机或相关专业本科以上学历，5-10年及以上相关工作经验；',
                '2. 精通MySQL及Innodb的体系结构和运行原理；',
                '3. 熟练掌握数据库调优、SQL调优，对锁和事务隔离问题有深入理解、能独立分析性能问题原因并解决；',
                '4. 精通MySQL、Mongodb、Redis备份与恢复，以及高可用集群方案；',
                '5. 有MySQL分库分表经验和Mongodb Sharding使用经验者优先；',
                '6. 熟悉常用分库分表解决方案，如Mycat、Atlas等；',
                '7. 精通Shell和Python'
              ]
            },
            {
              name: '岗位职责',
              arr: [
                '1. 数据库系统部署方案的 计划、设计和实施，参与项目的数据架构设计；',
                '2. 评审SQL、对数据库进行优化、数据库监控；',
                '3. 制定和实施数据库安全策略，扩容/数据备份/灾难恢复等；',
                '4. 协助开发数据库自动化运维平台；'
              ]
            },
            {
              name: '薪资福利',
              arr: [
                '上市公司+30k~50k+饭补话补+带薪培训+五险一金+生日关怀+团建活动+带薪休假+周末双休+年终奖金+老板Nice'
              ]
            }
          ]
        }
      ],
      mediaData: [
        {
          img: media_4,
          title: '凯特伟业CEO云凤程荣获2019中国数字中台年度创新人物',
          text:
            '2019年11月14日，由中国软件网主办，海比研究和光明网联合主办的第五届中国企业服务年会在北京香格里拉饭店圆满落幕。会上，凯特伟业CEO云凤程荣获《2019中国数字中台年度创新人物》称号，以表彰他在企业信息化落地中的不断耕耘与创新。',
          url: 'http://www.soft6.com/news/201911/26/360220.html'
        },
        {
          img: media_1,
          title: '专访凯特伟业CEO云凤程：实在人做实干型产品',
          text:
            '市场上有这么一种现象：一些公司尤其是上市公司内部孵化的某些技术部门在独立对外提供服务后总能做到闷声发大财，原因何在？因为这些公司经过内部多年的实践验证，所以他们相较于一些创业公司更懂业务，也更了解用户实际需求。然而，这些公司也存在诸如行业局限性等问题。',
          url: 'http://www.cniteyes.com/archives/35476'
        },
        {
          img: media_2,
          title: '凯特伟业CEO云凤程：打造专为SaaS而生的PaaS平台',
          text:
            '当IaaS寡头竞争格局已经基本落定，PaaS正在成为云计算市场的新变量。作为一种服务模式，PaaS可以推进SaaS的发展，提高Web平台上可利用的资源数量。在业内看来，SaaS市场规模虽然庞大，但增速最快的却是PaaS市场，也因此PaaS成为各大企业角逐未来的重要阵营。',
          url: 'https://finance.ifeng.com/c/7nRddc6nvZp'
        }
        // {
        //   img: media_3,
        //   title: '以实干赋能企业数字化创新 JEPaaS圆满出席第二届企业云服务大会',
        //   text: '2019年10月15日，由上海市软件行业协会、中国云体系产业创新战略联盟、拓普会展和国际云安全联盟联合主办的ECSC 2019第二届企业云服务大会在上海盛大举办。作为中国领先的实干型数字化中台SaaS快速开发领导者的JEPaaS云平台应邀出席本届大会。',
        //   url: 'http://www.jestq.com/JE/data/circle/news/wqVQvFBeUO85ViSYhTI.html?adopt=1',
        // },
      ],
      gsMap: mapImg //公司地图
    }
  },
  methods: {
    listShow(e) {
      const self = this
      self.addData.map(item =>
        item.id == e.id && !e.active
          ? (item.active = true)
          : (item.active = false)
      )
    },
    handleBtn() {
      this.$router.push({
        path: '/cooperation'
      })
    },
    openUrl(url) {
      if (!url) return
      window.open(url)
    }
  }
}
</script>

<style lang="less" scoped>
.adc-enter-active {
  transition: all 1s ease-out;
}
.adc-enter,
.adc-leave-to {
  opacity: 0;
}
.page_box {
  .box_top {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
  }
  .box_slot {
    text-align: center;
    width: 1200px;
    margin: 90px auto;
    border-bottom: 1px dotted rgba(204, 204, 204, 1);
    padding-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .slot_left {
      width: 450px;
      .title_o {
        text-align: left;
        font-size: 40px;
        font-weight: bold;
        color: rgba(0, 0, 0, 1);
        padding: 30px 0 60px 0;
      }
      .con_data {
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        > p {
          margin: 0;
        }
      }
      .hzgy {
        width: 140px !important;
        height: 50px !important;
        line-height: 50px !important;
        border-radius: 6px;
        margin: 0 !important;
        margin-top: 20px !important;
      }
    }
    .slot_right {
      display: flex;
      align-items: center;
      .map-view {
        height: 340px;
        img {
          height: 100%;
          object-fit: cover;
          cursor: pointer;
        }
      }
    }
    .bm-view {
      width: 650px;
      height: 370px;
    }
  }
  .box_o {
    text-align: center;
    .title_o {
      font-size: 40px;
      font-weight: bold;
      color: rgba(0, 0, 0, 1);
      padding: 60px 0 80px 0;
    }
    .con_o {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 130px;
      .img_o {
        width: 730px;
        height: 450px;
        border-radius: 4px;
        img {
          width: 700px;
          height: 450px;
        }
      }
      .data_o {
        width: 447px;
        text-align: justify;
        font-size: 18px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
      }
    }
  }
  .box_media {
    text-align: center;
    p {
      margin: 0;
      text-align: left;
    }
    .title_media {
      font-size: 40px;
      font-weight: bold;
      color: rgba(0, 0, 0, 1);
      padding: 60px 0 80px 0;
    }
    .media_content {
      width: 1200px;
      margin: 0 auto;
      padding-bottom: 100px;
      border-bottom: 1px dashed #cccccc;
      .media_items {
        width: 100%;
        display: flex;
        justify-content: space-between;
        > img {
          width: 300px;
        }
        > .text_wrp {
          width: 870px;
          > .title {
            color: #333333;
            font-size: 20px;
          }
          > .text {
            font-size: 16px;
            color: #666666;
            margin-top: 28px;
          }
          > .btn {
            display: block;
            width: 116px;
            height: 38px;
            border: 1px solid #cccccc;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-top: 20px;
            transition: all 300ms;
            background: url('../../../assets/imgs/about/arrow_gray.png')
              no-repeat 50% 50%;
            &:hover {
              background: url('../../../assets/imgs/about/arrow_white.png')
                no-repeat 50% 50%;
              background-color: #ff3041;
              border-color: #ff3041;
            }
          }
        }
        &:not(:first-child) {
          margin-top: 50px;
        }
      }
    }
  }
  .box_s {
    text-align: center;
    width: 100%;
    height: 1186px;
    background: rgba(236, 243, 249, 1);
    position: relative;
    .title_s {
      font-size: 40px;
      font-weight: bold;
      color: rgba(0, 0, 0, 1);
      padding: 120px 0 80px 0;
    }
    .box_listl {
      width: 440px;
      position: absolute;
      left: 17%;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding: 30px 70px;
    }
    .box_listl:before {
      content: '';
      display: block;
      width: 0px;
      height: 0px;
      border: 20px solid transparent;
      border-left: 30px solid transparent;
      border-left-color: rgba(255, 255, 255, 1);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 100%;
    }
    .box_listr {
      width: 440px;
      position: absolute;
      right: 17%;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding: 30px 70px;
    }
    .box_listr:before {
      content: '';
      display: block;
      width: 0px;
      height: 0px;
      border: 20px solid transparent;
      border-right: 30px solid transparent;
      border-right-color: rgba(255, 255, 255, 1);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 100%;
    }
    .list_title {
      font-size: 24px;
      font-weight: bold;
      color: rgba(255, 48, 65, 1);
      padding-bottom: 5px;
      text-align: left;
    }
    .list_data {
      font-size: 16px;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 24px;
      text-align: left;
    }
    .box_lin {
      width: 3px;
      height: 830px;
      background-color: rgba(204, 204, 204, 1);
      position: absolute;
      top: 236px;
      left: 50%;
      .list_lin {
        height: 830px;
        margin: 0 auto;
        margin-left: -12px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .lin_con {
          text-align: center;
          span {
            display: block;
            width: 20px;
            height: 20px;
            background: rgba(255, 48, 65, 1);
            border: 4px solid rgba(255, 255, 255, 1);
            box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
            border-radius: 50%;
          }
        }
      }
    }
  }
  .box_th {
    text-align: center;
    .title_th {
      font-size: 40px;
      font-weight: bold;
      color: rgba(48, 51, 54, 1);
      padding: 120px 0 80px 0;
    }
    .img_box {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .img_bor {
        padding: 20px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        height: 240px;
      }
      .img_bors {
        padding: 20px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        height: 290px;
        margin-top: 24px;
        &.img_height_auto {
          height: auto;
        }
      }
      .spe_img {
        height: 100%;
        padding-top: 44px;
      }
      .img_width1 {
        width: 321px;
      }
      .img_width2 {
        width: 170px;
      }
      .img_width3 {
        width: 340px;
      }
      .img_width4 {
        width: 170px;
      }
      .img_width5 {
        width: 420px;
        height: 290px;
      }
      .img_width6 {
        width: 430px;
        height: 290px;
      }
      .img_width7 {
        width: 200px;
        height: 290px;
      }
      .img_width11 {
        width: 366px;
        height: 512px;
      }
      .img_width12 {
        width: 734px;
        height: 512px;
      }
    }
    .data_box {
      width: 1200px;
      background: rgba(236, 243, 249, 1);
      box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 60px 0;
      margin: 40px auto 120px;
      p {
        font-size: 18px;
        font-weight: 400;
        color: rgba(48, 51, 54, 1);
        line-height: 30px;
      }
    }
  }
  .box_fi {
    text-align: center;
    .title_fi {
      font-size: 40px;
      font-weight: bold;
      color: rgba(48, 51, 54, 1);
      padding: 120px 0 80px 0;
    }
    .box_list {
      width: 1200px;
      margin: 15px auto;
      .list_title {
        cursor: pointer;
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1080px;
        height: 100px;
        border-radius: 4px;
        padding: 0 60px;
        .list_name {
          font-size: 20px;
          font-weight: 400;
        }
        .list_right {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-weight: 400;
          div {
            padding: 0 15px;
          }
          .list_but {
            padding: 0;
            margin-left: 40px;
            width: 34px;
            height: 34px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 3px 10px 0px rgba(144, 7, 18, 0.5);
            border-radius: 50%;
            text-align: center;
            line-height: 34px;
            // cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            .span_act {
              display: inline-block;
              border-right: 2px solid #ff3041;
              border-bottom: 2px solid #ff3041;
              width: 10px;
              height: 10px;
              transform: rotate(-135deg);
              margin-top: 8px;
            }
            .span_no {
              display: inline-block;
              border-right: 2px solid #ffffff;
              border-bottom: 2px solid #ffffff;
              width: 10px;
              height: 10px;
              transform: rotate(45deg);
              margin-top: -3px;
            }
          }
        }
      }
      .list_box {
        position: relative;
        padding: 50px 60px;
        width: 1080px;
        background: rgba(248, 248, 248, 1);
        text-align: left;
        .box_title {
          height: 30px;
          font-size: 18px;
          font-weight: bold;
          color: rgba(255, 48, 65, 1);
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
          line-height: 24px;
        }
        .contact_box {
          margin-top: 80px;
          font-size: 16px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          img {
            width: 22px;
            height: 22px;
            padding: 0 6px;
          }
          .iphone_m {
            margin-left: 60px;
          }
        }
      }
    }
  }
  .oot {
    text-align: center;
    margin-top: 100px;
  }
}
</style>
