<template>
  <div class="foo_box">
    <div class="box_con">
      <div class="con_li">
        <span class="con_title">联系我们</span>
        <div>
          <img
            src="../assets/imgs/footer/fo_1.png"
            alt=""
          >公&nbsp;&nbsp;&nbsp;&nbsp;司：北京凯特伟业科技有限公司
        </div>
        <!-- <div>
          <img
            src="../assets/imgs/footer/fo_2.png"
            alt=""
          >电&nbsp;&nbsp;&nbsp;&nbsp;话：010-82809807 / 400-0999-235
        </div> -->
        <div>
          <img
            src="../assets/imgs/footer/fo_3.png"
            alt=""
          >联系人：云先生 18610941078 、石女士  18519706638 、赵先生 13730131413
        </div>
        <div>
          <img
            src="../assets/imgs/footer/fo_4.png"
            alt=""
          >地&nbsp;&nbsp;&nbsp;&nbsp;址：北京市海淀区金澳国际写字楼16层
        </div>
        <div>
          <i class="jeicon jeicon-arrow-right" /> 友情链接：
          <span
            @click="openWin('https://suanbanyun.com')"
            style="cursor: pointer"
          >
            轻云蒜瓣
          </span>
          <span
            @click="openWin('http://www.jestq.com/')"
            style="cursor: pointer"
          >
            软件生态圈
          </span>
        </div>
      </div>
      <div class="box_right">
        <div class="right_data">
          关注官微得好礼
        </div>
        <div class="right_img">
          <img
            src="../assets/imgs/footer/ma.png"
            alt=""
          >
        </div>
      </div>
    </div>
    <div class="botton_data">
      <a
        href="https://beian.miit.gov.cn/"
        target="_blank"
      >京ICP备18023569号-5</a>
    </div>
  </div>
</template>

<script >
export default {
  name: 'Footer',
  data() {
    return {
    };
  },
  methods: {
    goPage(e, index) {
      if (e === 'home') {
        return this.$message({
          message: '敬请期待',
          type: 'warning',
          duration: 2000,
        });
      }
      const self = this;
      self.$store.commit('pageChange', index);
      self.$router.push({
        name: e,
      });
    },
    openWin(e) {
      window.open(e, e);
    },
  },
};
</script>

<style lang='less' scoped>
.foo_box {
  width: 100%;
  height: 290px;
  background-image: url("../assets/imgs/footer/footer.png");
  background-size: cover;
  z-index: 2;
  .box_con {
    padding: 70px 0 15px 0;
    position: relative;
    .con_li {
      padding-left: 400px;
      box-sizing: border-box;
      .con_title {
        display: block;
        font-size: 20px;
        font-weight: 400;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        padding-bottom: 20px;
      }
      & > div {
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 24px;
        display: flex;
        align-items: center;
        img {
          width: 14px;
          height: 14px;
          margin-right: 10px;
        }
        i {
          font-size: 12px;
          margin-right: 10px;
        }
        span {
          display: inline-block;
          line-height: 24px;
          display: flex;
          align-items: center;
          display: block;
          width: 100px;
        }
      }
    }
    .box_right {
      position: absolute;
      right: 400px;
      top: 70px;
      .right_data {
        font-size: 20px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        padding-bottom: 16px;
      }
      .right_img {
        display: inline-block;
        width: 142px;
        height: 142px;
        img {
          width: 142px;
          height: 142px;
        }
      }
    }
  }
  .botton_data {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    a {
      color: rgba(255, 255, 255, 1);
      text-decoration: none;
    }
  }
}
</style>
