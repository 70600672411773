<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-04-01 13:37:53
 * @LastEditTime: 2020-04-09 19:31:15
 -->
<template>
  <div
    class="btn"
    @click.stop="handleBtn"
  >
    {{ content }}
  </div>
</template>

<script>
export default {
  name: 'Btn',
  props: {
    content: {
      type: String,
      default: '提交',
    },
  },
  methods: {
    handleBtn() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="less" scoped>
  .btn {
    cursor: pointer;
    margin: 0px auto;
    background: rgba(255, 48, 65, 1);
    box-shadow: 2px 2px 10px 0px rgba(252, 44, 54, 0.4);
    border-radius: 30px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(254, 255, 255, 1);
    text-align: center;
    position: relative;
    width: 240px;
    height: 60px;
    line-height: 60px;
    margin-top: 50px;
  }
  .btn::after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0px;
    border-radius: 30px;
    z-index: -1;
    transition: background 0.7s;
  }
  .btn:hover:after {
    z-index: 2;
    background: rgba(51, 51, 51, 0.1);
  }
</style>
